import { connect } from "react-redux";
import { useParams } from "react-router";

import RecordView from "./record-view";
import { Container } from "@material-ui/core";

const HaulageRecordView = ({}) => {
  const { id }: any = useParams();

  return (
    <Container maxWidth="sm">
      <RecordView ticketId={id} />
    </Container>
  );
};

export default connect()(HaulageRecordView);
