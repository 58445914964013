import { connect } from "react-redux";
import { useParams } from "react-router";

import { makeStyles } from "@material-ui/core";
import { Ticket } from "../../interface/api/Ticket";
import { useEffect, useState } from "react";
import contractorProjectService from "../../services/contractorProjectService";
import RecordView from "./record-view";

const useStyles = makeStyles({
  ticketContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    height: "100vh",
  },
  ticket: {
    width: "45%",
  },
});

const HaulageRecordReportView = ({}) => {
  const { projectId }: any = useParams();
  const classes = useStyles();
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [ticketsLoaded, setTicketsLoaded] = useState(false);

  useEffect(() => {
    contractorProjectService.getProjectTickets(projectId).then((response) => {
      if (response.ok) {
        setTickets(response.data as Ticket[]);
        setTicketsLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    if (ticketsLoaded) {
      setTimeout(() => window.print(), 1000);
    }
  }, [ticketsLoaded]);

  return (
    <>
      {ticketsLoaded ? (
        <div className={classes.ticketContainer}>
          {tickets &&
            tickets.map((ticket, index) => (
              <div className={classes.ticket} key={index}>
                <RecordView
                  ticketId={ticket.id}
                  styles={{ marginBottom: 6, showLogo: false, fontSize: 12 }}
                />
              </div>
            ))}
        </div>
      ) : (
        <>Generating...</>
      )}
    </>
  );
};

export default connect()(HaulageRecordReportView);
